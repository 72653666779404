import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image';

import SEO from '../components/seo'
import Bio from '../components/Bio'
import Layout from '../components/Layout'
import { rhythm } from '../utils/typography'
import Categories from '../components/Categories/index'
import PostRightInfo from '../components/PostRightInfo/index'
import Pages from '../components/Pages/index'

class BlogList extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    // const posts = data.allMdx.edges
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages, allCategories } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout>
        <SEO
          title={siteTitle}
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        {/* <Bio /> */}
        <div className="content-wrapper">
          <div className="recent-posts">
            {posts.map(({ node }) => {
              const categories = node.frontmatter.categories
              const title = node.frontmatter.title || node.fields.slug
              const banner = node.frontmatter.banner
              return (
                <div key={node.fields.slug} className="archive-post">
                  <div className='post-title'>
                    <h3
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                      className="post-title"
                    >
                      <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                        {title}
                      </Link>
                    </h3>
                  </div>
                  
                  <PostRightInfo categories={categories} date={node.frontmatter.date}/>
                  <div className="title-abstract">
                    {banner && (
                      <Img
                        className="banner-image"
                        style={{marginBottom: '1rem'}}
                        sizes={banner.childImageSharp.sizes}
                      />
                    )}
                    {/* <MDXRenderer>{node.excerpt}</MDXRenderer> */}
                    <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  </div>
                </div>
              )
            })}
            {/* 页面底部分页模块 */}
            <Pages {...{currentPage,numPages,prevPage,nextPage,isFirst,isLast}}/>
            
          </div>
          <div className="sidebar">
            <Categories categories={allCategories} />
          </div>
        </div>
        
      </Layout>
    )
  }
}

export default BlogList

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip) {
      edges {
        node {
          excerpt(format: HTML)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM月DD日, YYYY")
            title
            categories
            banner {
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
