/*
 * @Author: konglingyuan
 * @Description: A Vue/React Project File
 * @Date: 2020-03-24 20:58:58
 * @LastEditors: konglingyuan
 * @LastEditTime: 2020-03-27 17:39:04
 */
import styled from 'styled-components'

const CategoriesWrapper = styled.div`
    width: 110px;
    margin-left: 0;
    float: left;
    display: inline;
    margin-left: 15px;
    margin-right: 15px;

    h1, h2, h3, h4, h5, h6 {
        margin-top: .5rem;
    }
    .categories-title{
        font-family: mercury ssm a,mercury ssm b;
        font-weight: 400;
        margin-bottom: .5em;
        color: #555;
    }
    h3 {
        font-size: .85rem;
        line-height: 1.3em;
        margin-bottom: .5em;
    }
    ul{
        padding: .5rem 0 1rem;
        list-style: none;
    }
    li{
        padding-top: .25rem;
        padding-bottom: .25rem;
    }
  
    a, a:visited {
        outline: 0;
        color: #821122;
        text-decoration: none;
        outline: 0;
        margin-bottom: 3px;
        line-height: 1em;
        font-size: .75rem;
        font-weight: 700;
        color: #000;
        border: none;
        box-shadow: unset;
    }
    a:hover {
        border-bottom: 1px solid #821122;
    }
    strong{
        margin-right:10px;
        font-weight: 700;
        color: #000;
        
    }
    .categories-content{
        margin-top: 1.5rem;
    }
    small{
        font-family: gotham ssm a,gotham ssm b;
        font-style: normal;
        font-weight: 400;
        font-size: .75rem;
        color: #666;
        margin-top: 6px;
        margin-bottom: 1rem!important;
        line-height: 1.4em;
    }
`
export default CategoriesWrapper